@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");

.cursive{
  font-family: 'Limelight', cursive;
  font-weight: 600;
}

.polaroid{
  font-family: 'Shadows Into Light', cursive;
  font-weight: 800;
}

.prose img{
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name{
  font-size: 150px;
}